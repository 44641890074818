<template>
  <div class="client-custom-fields">
    <div class="title-headline"><h4 class="mb-7">
      <span class="mr-1" v-if="typeName">{{ typeName }} -</span>Добавленные поля</h4></div>
    <div class="client-custom-fields__body" v-for="(i, index) in items" :key="i.id">
      <v-text-field
          class="evi-text-field mb-7 w-100"
          color="#44D370"
          :disabled="fieldDisabled"
          v-if="i.type === 'text'"
          v-model="i.value"
          @change="$emit('change', i)"
          hide-details
          :key="i.id"
      >
        <template v-slot:label>
          {{ i.name }}
        </template>
        <template v-slot:append>
          <v-icon size="18" color="#A7AFB8" v-if="canRemoveField" @click="openEditFieldModal(i)" class="mr-1">mdi-pencil</v-icon>
          <v-icon size="18" v-if="canRemoveField" @click="openRemoveFieldModal({id: i.id, index})" class="pointer" color="#A7AFB8">
            mdi-trash-can-outline
          </v-icon>
        </template>
      </v-text-field>
      <v-text-field
          class="evi-text-field mb-7 w-100"
          color="#44D370"
          :disabled="fieldDisabled"
          v-else-if="i.type === 'number'"
          type="number"
          v-model="i.value"
          @change="$emit('change', i)"
          hide-details
          :key="i.id"
      >
        <template v-slot:label>
          {{ i.name }}
        </template>
        <template v-slot:append>
          <v-icon size="18" color="#A7AFB8" v-if="canRemoveField" @click="openEditFieldModal(i)" class="mr-1">mdi-pencil</v-icon>
          <v-icon size="18" v-if="canRemoveField" @click="openRemoveFieldModal({id: i.id, index})" class="pointer" color="#A7AFB8">
            mdi-trash-can-outline
          </v-icon>
        </template>
      </v-text-field>
      <v-autocomplete
          class="evi-autocomplete mt-5 mb-7 w-100"
          color="#44D370"
          :disabled="fieldDisabled"
          v-else-if="i.type === 'select'"
          :items="i.values"
          v-model="i.value"
          @change="$emit('change', i)"
          hide-details
          :key="i.id"
          item-color="green"
      >
        <template v-slot:label>
          {{ i.name }}
        </template>
        <template v-slot:append>
          <v-icon size="18" color="#A7AFB8" v-if="canRemoveField" @click="openEditFieldModal(i)" class="mr-1">mdi-pencil</v-icon>
          <v-icon size="18" v-if="canRemoveField" @click="openRemoveFieldModal({id: i.id, index})" class="pointer" color="#A7AFB8">
            mdi-trash-can-outline
          </v-icon>
        </template>
      </v-autocomplete>
      <v-textarea
          no-resize
          class="evi-textarea mb-11 w-100"
          color="#44D370"
          :disabled="fieldDisabled"
          v-else-if="i.type === 'textarea'"
          v-model="i.value"
          @change="$emit('change', i)"
          hide-details
          :key="i.id"
      >
        <template v-slot:label>
          {{ i.name }}
        </template>
        <template v-slot:append>
          <v-icon size="18" color="#A7AFB8" v-if="canRemoveField" @click="openEditFieldModal(i)" class="mr-1">mdi-pencil</v-icon>
          <v-icon size="18" v-if="canRemoveField" @click="openRemoveFieldModal({id: i.id, index})" class="pointer" color="#A7AFB8">
            mdi-trash-can-outline
          </v-icon>
        </template>
      </v-textarea>
      <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
          v-else-if="i.type === 'date'"
          @change="$emit('change', i)"
          :disabled="fieldDisabled"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-bind="attrs"
              v-on="on"
              :label="i.name"
              color="#44D370"
              class="evi-text-field mb-11 w-100"
              :value="dateFormated(i.value)"
              @change="$emit('change', i)"
              hide-details
              :key="i.id"
          >
            <template v-slot:append>
          <v-icon size="18" color="#A7AFB8" v-if="canRemoveField" @click="openEditFieldModal(i)" class="mr-1">mdi-pencil</v-icon>
              <v-icon size="18" v-if="canRemoveField" @click="openRemoveFieldModal({id: i.id, index})" class="pointer" color="#A7AFB8">
                mdi-trash-can-outline
              </v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
            @input="menu = false"
            color="#44D370"
            first-day-of-week="1"
            :disabled="fieldDisabled"
            v-model="i.value"
            @change="$emit('change', i)"
        >
        </v-date-picker>
      </v-menu>
      <div class="evi-datetime w-100" v-else-if="i.type === 'datetime'">
        <v-datetime-picker
            dateFormat="dd.MM.yyyy"
            clearText="Отмена"
            ok-text="Принять"
            locale="ru-ru"
            :label="i.name"
            :textFieldProps="textFieldProps"
            :datePickerProps="datePickerProps"
            :timePickerProps="timePickerProps"
            :disabled="fieldDisabled"
            v-model="i.value"
            @input="$emit('change', i)"
            :key="i.value ? i.value.toString() : i.name"
            hide-details
        >
          <template v-slot:dateIcon>
            <v-icon>mdi-calendar</v-icon>
          </template>
          <template v-slot:timeIcon>
            <v-icon>mdi-clock</v-icon>
          </template>
        </v-datetime-picker>
        <div class="datetime-clear">
          <v-icon size="18" color="#A7AFB8" v-if="canRemoveField" @click="openEditFieldModal(i)" class="mr-1">mdi-pencil</v-icon>
          <v-icon size="18" v-if="canRemoveField" @click="openRemoveFieldModal({id: i.id, index})" class="pointer" color="#A7AFB8">
            mdi-trash-can-outline
          </v-icon>
        </div>
      </div>
    </div>
    <v-dialog
        v-model="removeFieldModal"
        max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Удаление поля
        </v-card-title>
        <v-card-text>Вы действительно хотите удалить поле?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="removeFieldModal = false"
          >
            Отмена
          </v-btn>
          <v-btn
              color="red"
              text
              @click="removeField"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="editFieldModal"
        max-width="612px"
    >
      <CreateField @editField="editField" :selectedField="selectedField" @close="closeEditModal"/>
    </v-dialog>
  </div>
</template>

<script>
import CreateField from "@/components/clients/CreateField";
export default {
  name: "CustomFields",
  components: {CreateField},
  props: {
    items: {
      type: Array,
    },
    fieldDisabled: {
      type: Boolean,
    },
    canRemoveField: {
      type: Boolean,
      default: false,
    },
    typeName: {
      type: String,
    }
  },
  data () {
    return {
      menu: false,
      removeFieldModal: false,
      editFieldModal: false,
      selectedField: null,
      textFieldProps: {
        color: '#44D370',
      },
      datePickerProps: {
        color: '#44D370',
        firstDayOfWeek: 1,
      },
      timePickerProps: {
        color: '#44D370',
        format: "24hr",
      },
    }
  },
  methods: {
    openRemoveFieldModal (item) {
      this.selectedField = {id: item.id, index: item.index};
      this.removeFieldModal = true;
    },
    openEditFieldModal (item) {
      this.selectedField = item;
      this.editFieldModal = true;
    },
    closeEditModal () {
      this.selectedField = null;
      this.editFieldModal = false
    },
    dateFormated (val) {
      if (val) {
        return this.$moment(val).format('DD.MM.YYYY');
      }
      return '';
    },
    removeField () {
      this.$emit('removeField', {id: this.selectedField.id, index: this.selectedField.index});
      this.removeFieldModal = false;
    },
    editField (val) {
      this.$emit('editField', val);
    }
  }
}
</script>

<style lang="scss" scoped>
.evi-datetime {
  position: relative;
  .chat-sidebar .client-custom-fields__body .v-input {
    margin-bottom: 0 !important;
  }
}
.datetime-clear {
  position: absolute;
  right: 0;
  top: 15px;
}
</style>
