import {mapActions, mapState} from "vuex";

export var DealAutocompleteRequestsMixin = {
    data: () => ({
        dealSearch: "",
        timerId: null,
        dealPage: 0,
        dealPerPage: 20,
        dealLoading: false,
    }),
    computed: {
        ...mapState("deals", ['deals',]),
    },
    methods: {
        ...mapActions({
            getDeals: 'deals/getDealsBy',
            addDeals: 'deals/addDealsBy',
        }),
        getDealsBy (search) {
            this.dealPage = 1;
            this.dealLoading = true;
            let args = {
                per_page: this.dealPerPage,
                page: this.dealPage,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.getDeals(args).then(() => {
                this.dealLoading = false;
            });
        },
        addDealsBy (search) {
            this.dealPage++;
            this.dealLoading = true;
            let args = {
                per_page: this.dealPerPage,
                page: this.dealPage,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.addDeals(args).then(() => {
                this.dealLoading = false;
            });
        },
        loadDeals () {
            this.getDealsBy("");
        },
        dealIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.addDealsBy(this.dealSearch);
            }
        },
        searchDeal (val) {
            if (val) {
                this.dealPage = 0;
                if (this.timerId)
                    clearTimeout(this.timerId);

                this.timerId = setTimeout(() => {
                    this.getDealsBy(val);
                }, 500);
            }
        },
    },
};
