<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">Выставление счёта</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="billNumber"
              placeholder="Номер счёта"
              :rules="nameRules"
          >
            <template v-slot:label>
              Номер счёта
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-autocomplete
              class="evi-autocomplete"
              color="#44D370"
              :items="localDeals"
              v-model="deal"
              item-text="name"
              item-value="id"
              :loading="dealLoading"
              @focus="loadDeals"
              :search-input.sync="dealSearch"
              @input.native="searchDeal(dealSearch)"
              clearable
              item-color="green"
              return-object
          >
            <template v-slot:label>
              Сделка
              <span class="important">*</span>
            </template>
            <template v-slot:append-item>
              <div v-intersect="dealIntersect"/>
            </template>
            <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
            </template>
          </v-autocomplete>
          <v-autocomplete
              class="evi-autocomplete"
              color="#44D370"
              :items="localClients"
              v-model="client"
              item-text="name"
              item-value="id"
              :loading="clientsLoading"
              @focus="loadClients"
              :search-input.sync="clientSearch"
              @input.native="searchClient(clientSearch)"
              clearable
              item-color="green"
          >
            <template v-slot:label>
              Клиент
              <span class="important">*</span>
            </template>
            <template v-slot:append-item>
              <div v-intersect="clientIntersect"/>
            </template>
            <template v-slot:item="{item}">
              <v-avatar
                  color="#FFFFFF"
                  size="30"
                  class="user-page__avatar mr-3"
              >
                <img
                    :src="item.avatar_url ||require('@/assets/img/users/profile-avatar.png')"
                    alt=""
                >
              </v-avatar>
              <div class="v-list-item__title">{{ item.name }}</div>
            </template>
            <template v-slot:append>
                <span class="evi-autocomplete__arrow">
                    <v-icon>mdi-chevron-down</v-icon>
                </span>
            </template>
          </v-autocomplete>
          <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateFormated"
                  v-bind="attrs"
                  v-on="on"
                  readonly
                  label="Дата выставления счета"
                  color="#44D370"
                  class="evi-text-field"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="date"
                @input="menu = false"
                color="#44D370"
                first-day-of-week="1"
            ></v-date-picker>
          </v-menu>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="reason"
          >
            <template v-slot:label>
              Основание для счета (договор)
            </template>
          </v-text-field>
          <v-text-field
              class="evi-text-field"
              color="#44D370"
              v-model="sum"
              readonly
          >
            <template v-slot:label>
              Сумма сделки
            </template>
          </v-text-field>
          <v-select
              class="evi-select"
              color="#44D370"
              v-model="nds"
              :items="ndsItems"
              item-text="name"
              item-value="id"
              label="НДС"
              item-color="green"
              return-object
          >
            <template v-slot:append>
              <span class="evi-select__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
          </v-select>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          Выставить счёт
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {ClientAutocompleteRequestsMixin} from "@/mixins/autocomplete/ClientAutocompleteRequestsMixin";
import {DealAutocompleteRequestsMixin} from "@/mixins/autocomplete/DealAutocompleteRequestsMixin";

export default {
  mixins: [ClientAutocompleteRequestsMixin, DealAutocompleteRequestsMixin],
  props: {
    currentClient: {
      type: Object,
    },
    currentDeal: {
      type: Object,
    },
  },
  data: () => ({
    valid: true,
    billNumber: '',
    date: '',
    menu: false,
    sum: '',
    nameRules: [(v) => !!v || 'Заполните поле'],
    client: null,
    deal: null,
    nds: 0,
    reason: '',

  }),
  computed: {
    ...mapState("bills", ["ndsItems"]),
    localClients() {
      if (this.clients.length) {
        if (this.deal) return [...this.clients, this.deal.client];
        return this.clients;
      } else if (this.currentClient) {
        return [this.currentClient];
      } else if (this.deal) {
        return [this.deal.client];
      }
      return [];
    },
    localDeals() {
      if (this.deals.length) {
        return this.deals;
      } else if (this.currentDeal) {
        return [this.currentDeal];
      }
      return [];
    },
    dateFormated() {
      return this.$moment(this.date).format('DD.MM.YYYY');
    },
  },
  methods: {
    ...mapActions({
      createBill: 'bills/createBill',
    }),
    validate() {
      return !!this.$refs.form.validate();
    },
    create() {
      if (this.validate()) {
        let payload = {
          number: this.billNumber,
          client_id: this.client,
          deal_id: this.deal.id,
          bill_date: this.date,
          tax: this.nds.id,
          reason: this.reason,
        };

        this.createBill(payload).then(() => {
          this.$emit('close');
          this.$emit('update');
        });
      }
    },
    closeDialog() {
      this.$emit('close');
      this.$refs.form.resetValidation();
    },
    selectDeal(val) {
      this.client = val.client.id;
      this.sum = val.sum;
    },
  },
  mounted() {
    this.nds = this.ndsItems[0];
    this.date = this.$moment(new Date()).format('YYYY-MM-DD');
    if (this.currentDeal) {
      this.deal = this.currentDeal;
    }
    if (this.currentClient) {
      this.client = this.currentClient.id;
    }
  },
  watch: {
    deal(val) {
      if (val) {
        this.selectDeal(val);
      }
    }
  }
}
</script>

<style scoped>

</style>
