<template>
  <v-card class="modal-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11">{{ selectedField ? 'Редактирование поля' : 'Добавление поля' }}</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')" />
        </div>
        <v-col
            cols="12"
        >
          <v-text-field
              class="evi-text-field mb-11"
              color="#44D370"
              v-model="name"
              placeholder="Наименование"
              :rules="nameRules"
          >
            <template v-slot:label>
              Наименование поля
              <span class="important">*</span>
            </template>
          </v-text-field>
          <v-select
              class="evi-select"
              color="#44D370"
              v-model="selectedType"
              :items="fieldTypes"
              item-text="name"
              item-value="type"
              label="Тип"
              :rules="typeRules"
              item-color="green"
          >
            <template v-slot:label>
              Тип
              <span class="important">*</span>
            </template>
            <template v-slot:append>
              <span class="evi-select__arrow">
                  <v-icon>mdi-chevron-down</v-icon>
              </span>
            </template>
          </v-select>
          <div v-if="selectedType === 'select'">
            <h4 class="mb-5">Значения для списка:</h4>
            <v-text-field
                class="evi-text-field mb-5"
                color="#44D370"
                v-for="(item, index) of values"
                v-model="item.name"
                placeholder="Наименование"
                :rules="nameRules"
                :key="index"
            >
              <template v-slot:label>
                Наименование значения
                <span class="important">*</span>
              </template>
            </v-text-field>
            <div class="add-field pointer mb-2" @click="newValue">
              <v-icon>mdi-plus</v-icon>
              Добавить значение
            </div>
          </div>
        </v-col>
      </v-card-text>
      <v-card-actions class="modal-create__actions">
        <v-btn class="evi-button-green evi-button-green--fill" @click="create">
          {{ selectedField ? 'Сохранить поле' : 'Добавить поле' }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>

export default {
  name: "CreateField",
  props: {
    selectedField: {
      type: Object,
      required: false,
    },
    isFormFields: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    valid: true,
    name: '',
    nameRules: [(v) => !!v || 'Введите наименование поля'],
    typeRules: [(v) => !!v || 'Выберите тип поля'],
    selectedType: null,
    fieldTypes: [
      {
        name: 'Текстовое поле',
        type: 'text',
      },
      {
        name: 'Текстовая область',
        type: 'textarea',
      },
      {
        name: 'Числовое поле',
        type: 'number',
      },
      {
        name: 'Дата',
        type: 'date',
      },
      {
        name: 'Дата и время',
        type: 'datetime',
      },
      {
        name: 'Список',
        type: 'select',
      },
    ],
    values: [],
  }),
  methods: {
    validate () {
      return !!this.$refs.form.validate();
    },
    async create () {
      if (this.validate()) {
        let payload = {
          name: this.name,
          type: this.selectedType,
        };
        if (this.selectedType === 'select') {
          payload.values = this.values.map(i => i.name);
        }

        if (this.selectedField) {
          payload.id = this.selectedField.id;
          await this.$emit('editField', payload);
        } else {
          await this.$emit('createField', payload);
        }
        this.closeDialog();
      }
    },
    closeDialog () {
      this.$emit('close');
      this.name = '';
      this.selectedType = null;
      this.$refs.form.resetValidation();
    },
    newValue () {
      this.values.push({name: `Новое значение ${this.values.length + 1}`});
    },
    initSelectedField (val) {
      if (val) {
        if (this.isFormFields) {
          this.name = val.placeholder;
        } else {
          this.name = val.name;
        }
        this.selectedType = val.type;
        if (val.values) {
          this.values = val.values.map((item, index) => ({name: item, id: index}));
        }
      } else {
        this.name = '';
        this.selectedType = null;
        this.values = [];
      }
    }
  },
  mounted () {
    if (this.selectedField) this.initSelectedField(this.selectedField);
  },
  watch: {
    selectedField(val) {
      this.initSelectedField(val);
    }
  }
}
</script>

<style scoped>

</style>
