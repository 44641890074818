import {mapActions, mapState} from "vuex";

export var ClientAutocompleteRequestsMixin = {
    data: () => ({
        clientSearch: "",
        timerId: null,
        clientPage: 0,
        clientPerPage: 20,
        clientsLoading: false,
    }),
    computed: {
        ...mapState("clients", ['clients',]),
    },
    methods: {
        ...mapActions({
            getClients: 'clients/getClientBy',
            addClients: 'clients/addClientBy',
        }),
        getClientsBy (search) {
            this.clientPage = 1;
            this.clientsLoading = true;
            let args = {
                per_page: this.clientPerPage,
                page: this.clientPage,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.getClients(args).then(() => {
                this.clientsLoading = false;
            });
        },
        addClientsBy (search) {
            this.clientPage++;
            this.clientsLoading = true;
            let args = {
                per_page: this.clientPerPage,
                page: this.clientPage,
            };
            if (search) {
                args['filter[name]'] = search;
            }
            this.addClients(args).then(() => {
                this.clientsLoading = false;
            });
        },
        loadClients () {
            this.getClientsBy("");
        },
        clientIntersect(entries, observer, isIntersecting) {
            if (isIntersecting) {
                this.addClientsBy(this.clientSearch);
            }
        },
        searchClient (val) {
            if (val) {
                this.clientPage = 0;
                if (this.timerId)
                    clearTimeout(this.timerId);

                this.timerId = setTimeout(() => {
                    this.getClientsBy(val);
                }, 500);
            }
        },
    },
    mounted() {
        this.loadClients();
    }
};
